<template>
  <div class="page-list personnelfiles flex-1">
    <a-row class="filter-wrap  ">
      <a-col
        :lg="8"
        :md="24"
        :sm="24"
        :xs="24"
        style="margin-left:0px;"
        class="mgb-20"
      >
        <a-button
          type="primary"
          class="table-btn-clk"
          style="margin-right:19px;margin-left: 0px;"
          @click="addRules"
        >添加模板</a-button>
      </a-col>
      <a-col
        :lg="16"
        :md="24"
        :sm="24"
        :xs="24"
        class="text-right flex align-item-center j-end "
      >
        <span style="margin-right:-21px;">模板code：</span>
        <a-input
          @search="getList"
          @pressEnter="getList"
          v-model="query.template_code"
          placeholder="请输入模板code"
          class="mgl-20 mgr-20 ant-input2"
          style="width: 168px;"
        >
        </a-input>
        <span style="margin-right:-21px;">模板名称：</span>
        <a-input
          @search="getList"
          @pressEnter="getList"
          v-model="query.template_name"
          placeholder="请输入模板名称"
          class="mgl-20 mgl-20 ant-input2"
          style="width: 168px;"
        >
        </a-input>

        <a-button
          class="mgl-20 table-btn-clk"
          type="primary"
          style="height:32px;margin-top:1px;"
          @click="getList()"
        >查询</a-button>
      </a-col>
    </a-row>
    <a-table
      class="table-manage flex-1"
      @change="tableChanged"
      :pagination="table.pagination"
      row-key="template_id"
      :loading="table.loading"
      :columns="config.message.columns"
      :data-source="table.tableData"
    >
      <template
        slot="_2"
        slot-scope="action, record"
      >
        <a-tooltip>
          <template slot="title">
            {{  record.template_info}}
          </template>
          {{  record.template_info || '--'}}
        </a-tooltip>
      </template>
      <template
        slot="_6"
        slot-scope="action, record"
      >
        <a-tooltip>
          <template slot="title">
            {{  record.template_third_content}}
          </template>
          {{  record.template_third_content || '--'}}
        </a-tooltip>
      </template>
      <template
        slot="_7"
        slot-scope="action, record"
      >
        <a-button
          size="small"
          class="mgr-12 smallbtn"
          @click="addRules(record,3)"
        >编辑</a-button>
        <a-button
          size="small"
          class="mgr-12 smallbtn"
          @click="handleBatch('delete',[record],'提示')"
        >删除</a-button>
      </template>
    </a-table>
    <!-- 编辑添加  -->
    <a-modal
      :visible="permissionInfo.visible"
      width="750px"
      wrapClassName="commonModel"
      @cancel="permissionInfo.visible=false"
      :okText="permissionInfo.text"
      @ok="okclickmod"
      destroyOnClose
      :bodyStyle="{height: '158px'}"
    >
      <div class="constmodel">
        <a-row
          type="flex"
          style="justify-content: space-between;"
        >
          <a-col>
            <div class="linebox flex align-item-center">
              <div class="lfbox"><span class="imgxing">*</span>模板code：</div>
              <div class="rgbox">
                <a-input
                  v-model="permissionInfo.info.template_code"
                  placeholder="请输入模板code"
                ></a-input>
              </div>
            </div>
          </a-col>
          <a-col>
            <div class="linebox flex align-item-center">
              <div class="lfbox"><span class="imgxing">*</span>模板名称：</div>
              <div class="rgbox">
                <a-input
                  v-model="permissionInfo.info.template_name"
                  placeholder="请输入模板名称"
                ></a-input>
              </div>
            </div>
          </a-col>
        </a-row>
        <a-row
          type="flex"
          style="justify-content: space-between;"
        >
          <a-col>
            <div class="linebox flex">
              <div class="lfbox"><span class="imgxing">*</span>模板描述：</div>
              <div
                class="rgbox"
                style="width:198px;"
              >
                <a-textarea
                  style="resize:none;height:60px;"
                  v-model="permissionInfo.info.template_info"
                  placeholder="请输入模板描述"
                ></a-textarea>
              </div>
            </div>
          </a-col>
          <a-col>
            <div class="linebox flex align-item-center">
              <div class="lfbox"><span class="imgxing">*</span>模板状态：</div>
              <div class="rgbox">
                <a-radio-group v-model="permissionInfo.info.template_status">
                  <a-radio :value="0">
                    未激活
                  </a-radio>
                  <a-radio :value="1">
                    已激活
                  </a-radio>
                </a-radio-group>
              </div>
            </div>
          </a-col>
        </a-row>

      </div>
      <template slot="title">
        <div style="margin: 3px 0;">
          <span class="titmodel">{{ permissionInfo.title }}</span>
        </div>
      </template>
    </a-modal>
  </div>

</template>
<script>  
import { GetsmsList, CreateSms, UpdateSms, GetSmsInfo } from '@/api/permission'
import config from './config'
import Uploadimg from '@/components/intimate/uploadimg'
export default {
  components: { Uploadimg, },

  data() {
    return {
      config,
      query: {
        template_name: undefined,
        template_code: undefined,
        per_page: 10,
        page: 1,
      },
      table: {
        tableData: [],
        loading: false,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
        },
      },
      permissionInfo: {
        visible: false,
        text: '提交',
        title: '',
        type: 1,//1新增   3编辑
        pic_url_loading: false,
        isDisabled: false,
        info: {
          template_code: undefined,
          template_name: undefined,
          template_info: undefined,
          template_id: undefined,
          template_status: 1, // 0未激活、1已激活

        }
      },

    }
  },
  async created() {
    this.getList()
  },
  methods: {

    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.permissionInfo.info.pic_url_loading = true
        return;
      }
      if (info.file.status === 'done') {
        const { url } = info.file.response.data
        this.permissionInfo.info.staff_avatar = url
        this.permissionInfo.info.pic_url_loading = false
      }
    },
    // 删除 
    handleBatch(key, record, title = "删除") {
      let self = this
      let deleteRules = ''
      const urlArr = [
        { name: deleteRules, key: 'delete', okType: 'primary', msg: '确定要删除吗？删除后将无法恢复，请谨慎操作！' },
      ]
      const activeObj = urlArr.filter(it => it.key == key)[0]
      self.$confirm({
        icon: 'exclamation-circle',
        closable: true,
        title,
        content: activeObj.msg,
        okText: '确定',
        closable: true,
        okType: activeObj.okType,
        cancelText: '取消',
        onOk() {
          const data = {
            employee_ids: selectedRowKeys,
          };
          (activeObj.name)(data).then(res => {
            self.getList()
          }).finally(r => {
          })
        },
        onCancel() {
        },
      });
    },
    getList() {
      let self = this
      self.table.loading = true
      const params = {
        filter: {
          template_name: self.query.template_name,
          template_code: self.query.template_code
        },
        page: self.query.page,
        per_page: self.query.per_page
      }

      return GetsmsList(params).then(res => {
        console.log(res.data, "data");
        const { list = [], total = 0 } = res.data
        self.table.tableData = list
        self.table.pagination = Object.assign({}, { pageSize: this.query.per_page, current: this.query.page }, {
          total: total
        })
        console.log(self.table.tableData, "///self.table.tableData");
      }).finally(r => {
        self.table.loading = false
      })
    },
    // 角色 列表
    getGetRoleidList(e) {
      GetRoleid().then(res => {
        this.treeRecursion(res.data.list)
        this.roleList = res.data.list
        console.log(this.roleList, '>>>this.roleList');
      })
    },

    tableChanged(pagination, filters, sorter, { currentDataSource }) {

      const { current, pageSize } = pagination
      const { field, order } = sorter
      this.query = Object.assign(this.query, { per_page: Number(pageSize), page: Number(current) })
      this.getList()
    },


    // 提交按钮
    okclickmod(e) {
      let data = {
        ...this.permissionInfo.info
      }

      // data.staff_role = this.permissionInfo.info.staff_role  
      if (this.permissionInfo.type == 3) {
        delete data.company_id
        delete data.company_path
        delete data.staff_last_login_time
        delete data.staff_nickname
        delete data.staff_system_list
        delete data.user_id
        UpdateSms(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getList()
          }
        })
      } else {
        CreateSms(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getList()
          }
        }).catch(err => {
          console.log(err);
        })
      }
    },
    async addRules(e, i) {
      this.permissionInfo.info = {
        template_code: undefined,
        template_name: undefined,
        template_info: undefined,//简称
        template_id: this.permissionInfo.info.template_id,
        template_status: 1, // 0未激活、1已激活
      }
      if (i == 3) {
        this.permissionInfo.title = '编辑模板'
        this.permissionInfo.info.template_id = e.template_id
        this.permissionInfo.type = 3
        this.openDatails()
        this.permissionInfo.visible = true
        console.log(this.permissionInfo.info, 'permissionInfo.info');
      } else {
        this.permissionInfo.title = '添加模板'
        this.permissionInfo.type = 1
        delete this.permissionInfo.info.template_id
        console.log(this.permissionInfo);
        this.permissionInfo.visible = true
      }
    },

    openDatails() {
      GetSmsInfo({ template_id: this.permissionInfo.info.template_id }).then(res => {
        delete res.data.created_at
        delete res.data.updated_at
        delete res.data.template_third_content
        delete res.data.template_third_id
        this.permissionInfo.info = Object.assign(this.permissionInfo.info, res.data)
        console.log(res.data, "???", this.permissionInfo.info);
      })
    },
  },

}
</script>
<style lang="less" scoped>
.personnelfiles {
  // padding: 12px;
  margin: 20px 12px 20px 20px;
  overflow: hidden;
  // background: #fff;
  // height: 100%;
  // min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  .filter-wrap {
    background: #fff;
    border-radius: 4px 4px 0 0 ;
  }
  /deep/ .ant-checkbox-wrapper {
    background: transparent;
    border: none;
    box-shadow: none;
  }
}
.borderbox {
  margin-left: 4px;
  display: inline-block;
  border-radius: 6px;
  text-align: center;
  width: 20px;
  height: 18px;
  background: @btn2mianbgDColor;
  box-shadow: 0px 2px 3px 0px @btn2mianOutDColor,
    inset 0px -2px 0px 0px @btn2mianInsertDColor;
  border-radius: 4px;
  border: 1px solid @btn2mianboderDColor;
  line-height: 14px;

  // display: flex;
  // align-items: center;
  // justify-content: center;
}
.bigdropdown_imga {
  display: none;
}
.bigdropdown_img1a {
  display: none;
}
.bigdropdown {
  position: relative;
  width: 100px;
  height: 18px;
  display: inline-block;
  line-height: 18px;
  .borderbox {
    position: absolute;
    top: 0;
    left: 29px;
    i {
      font-size: 8px;
      margin-top: -1px;
      transform: scale(0.83);
      display: inline-block;
      color: @btn2mianfontDColor;
    }
    &:hover {
      border: 1px solid @btn2mianboderHColor;
      i {
        color: @btn2mianfontHColor;
      }
    }
  }
}
/deep/ .bgchangenode {
  background: #ccc;
}
/deep/.ant-modal-header {
  padding-left: 24px;
}
/deep/.ant-modal-footer {
  padding-bottom: 32px;
}
.btn-padding {
  display: none;
  // background: rgba(216, 219, 236, 0.27);
  border-radius: 4px;
  color: @btn3mianFontDColor;
  background: #f5f5fa;
  border: none !important;
  box-shadow: none !important;
  &:hover {
    color: @btn3mianFontHColor !important;
    background-color: @btn3mianbgHColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  &:focus {
    color: @btn3mianFontFColor !important;
    background-color: @btn3mianbgFColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  // border: 1px solid @btn2mianboderDColor;
  // box-shadow: 0px 2px 4px 0px @btn2mianOutDColor, inset 0px -2px 0px 0px @btn2mianInsertDColor;
  // background-color: @btn2mianbgDColor;
  // color: @btn2mianfontDColor;
  // &:active {
  //   color: @btn2mianfontFColor !important;
  //   border: 1px solid @btn2mianboderFColor !important;
  //   background-color: @btn2mianbgFColor !important;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutFColor, inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  // }

  // &:focus {
  //   color: @btn2mianfontFColor !important;
  //   border: 1px solid @btn2mianboderFColor !important;
  //   background-color: @btn2mianbgFColor;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutFColor, inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  // }

  // &:hover {
  //   color: @btn2mianfontHColor !important;
  //   border: 1px solid @btn2mianboderHColor !important;
  //   background-color: @btn2mianbgHColor;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutHColor, inset 0px -2px 0px 0px @btn2mianInsertHColor;
  // }
}
/deep/ .ant-table-header {
  // margin-top: -23px;
}
/deep/ .borderbox.ant-dropdown-open {
  border-color: @baseColor35 !important;
  color: @baseColor35 !important;
  i {
    color: @baseColor35 !important;
  }
}
/deep/
  .ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-cascader-menu-item:hover,
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-tree li .ant-tree-node-content-wrapper:hover,
.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: @selectdownbgHColor !important;
  // color: #0A955D;
  color: @selectdownfontHColor;
  font-weight: 400;
}
.smallbtnlong {
  letter-spacing: -1px !important;
}
/deep/ .ant-table-body {
  overflow: hidden !important;
  overflow-y: scroll !important;
}
.meiye-icon {
  color: @primaryColor;
}
/deep/ .ant-input2 .ant-input {
  padding-top: 5px;
  &::placeholder {
    padding-top: 3px;
  }
}
.linebox {
  display: flex;
  padding: 12px 0;

  .lfbox {
    width: 110px;
    font-size: 14px;
    color: @fontColor5;
    text-align: left;
    .imgxing {
      width: 8px;
      height: 22px;
      color: red;
    }
  }

  .rgbox {
    font-size: 14px;
    color: @btnColorfont1;
    // width: calc(100% - 120px);
    position: relative;

    .tuijia {
      font-size: 14px;
      color: @fontColor6;
    }
  }
}
/deep/ .ant-upload-picture-card-wrapper .ant-upload {
  width: 45px !important;
  height: 45px !important;
  padding: 0;
}
/deep/ .ant-upload-select-picture-card i {
  font-size: 14px;
}
</style>